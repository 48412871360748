import axios, {AxiosError} from "axios";
import {setUserInfo} from "../pages/auth/authSlice";

import {store} from "store";
import {EpacFormType} from "../types/epac.type";

const SERVICES: any = {
    CORE: process.env.REACT_APP_CORE_SERVICE_URL,
    PATIENT: process.env.REACT_APP_PATIENT_SERVICE_URL,
    COMMON: process.env.REACT_APP_COMMON_SERVICE_URL || process.env.REACT_APP_CORE_SERVICE_URL,
}

const serviceCreator = (baseUrl: string) => {
    const isProd = process.env.NODE_ENV !== "development";

    const service = axios.create({
        baseURL: baseUrl,
        timeout: 30000,
        timeoutErrorMessage: "Request Timeout",
    });

    service.interceptors.request.use(
        function (config: any) {
            const token = store.getState().auth.user?.accessToken;

            if (token) {
                config.headers.common["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        function (error) {
            if (!isProd) {
                //console.log(error);
            }
            return Promise.reject(error);
        }
    );

    service.interceptors.response.use(
        function (response) {
            if (!isProd) {
                //console.log("SUC Resp: ", response.data);
            }

            return response;
        },
        function (error) {
            if (error.response) {
                if (error.response?.status === 401) {
                    store.dispatch(setUserInfo(null));
                }

                if (!isProd) {
                    //console.log("ERR Resp: ", error.response);
                }
                return {
                    status: error.response?.status || 400,
                    data: error.response?.data
                };
            }

            if (!isProd) {
                //console.log("Err: ", error);
            }

            return Promise.reject(error);
        }
    );
    return service;
}

const coreService = serviceCreator(SERVICES.CORE);
const patientService = serviceCreator(SERVICES.PATIENT);
const commonService = serviceCreator(SERVICES.COMMON);

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const formatPhone = (phone: string) => {
    if (phone) {
        const phones = phone.split('');
        return `${phones[0] || ''}${phones[1] || ''}${phones[2] || ''} ${phones[3] || ''}${phones[4] || ''}${phones[5] || ''}${phones[6] || ''} ${phones[7] || ''}${phones[8] || ''}${phones[9] || ''}${phones[10] || ''}`
    }
    return '';
}

export const getUserInfo = (id: string) => {
    return patientService.get(`/patients/${id}`);
}

export const postEpacUpdate = (data: EpacFormType) => {
    return patientService.post(`/patients/pac/update`, data);
}

export const uploadCaseFile = async (file: File | Blob, id: string) => {
    const formdata = new FormData();
    // @ts-ignore
    formdata.append("files", file, (file?.name || `${Date.now()}.png`));
    const {data, status} = await commonService.post(`/upload?folder=${id}`, formdata);
    if (status === 200) {
        return data?.data[0] || ''
    }
    return "";
}

export default patientService;
