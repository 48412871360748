import React from "react";
import {Container, Checker, Text} from "./styles";

export default function Checkbox(props: {
  label?: string,
  placeholder?: string,
  onChange?: any,
  checked?: boolean,
}) {

  return (
    <Container>
      <Checker checked={!!props.checked} onChange={props.onChange}/>
      {props?.label ? <Text>{props.label}</Text> : null}
    </Container>
  )
}