import React from "react";
import styled from "styled-components";
import {InputNumber} from 'antd';
import * as antd from 'antd';

export const Container = styled.div``;
export const Label = styled.label`
  font-weight: 600;
  font-size: 20px !important;
  line-height: 30px;
  color: #1B1B1B;
  margin-bottom: ${props => props.theme.marginBottom || '16px'} !important;
  display: block;
`;

export const LabelRegular = styled.label`
  margin: 0 0 8px 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5D5D5D;

  @media (max-width: 600px) {
    font-size: 12px !important;
    line-height: 18px;
    &.title {
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #1B1B1B;
      margin-bottom: -16px;
      display: block;

      span {
        display: inline-block !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        margin-left: 3px !important;
      }
    }

    &.label-sign {
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      color: #1B1B1B !important;
      margin: 0;
    }
  }
`;

export const LabelRegular2 = styled.label`
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #1B1B1B !important;
`;

export const LabelRegular3 = styled.div`
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1B1B1B !important;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const LabelRegular4 = styled.label`
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #828282 !important;
`;

export const LabelRegular5 = styled.label`
  font-weight: 500 !important;
  font-size: 30px !important;
  line-height: 44px !important;
  color: #1B1B1B !important;

  @media (max-width: 600px) {
    &.title {
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #1B1B1B;
      display: block;
    }
  }
`;

export const LabelRegular6 = styled.label`
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #1B1B1B !important;

  @media (max-width: 600px) {
    &.epac-form {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }
`;

export const LabelRegular7 = styled.label`
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #1B1B1B !important;
`;

export const LabelRegular8 = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2A2F36;
`;

export const LabelRegular9 = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #727C8A;
`;

export const LabelRegular10 = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #7EC820;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const LabelRegular11 = styled.span`
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1B1B1B !important;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Input = styled(antd.Input)`
  padding: 13px 0px 13px 20px;
  width: 100%;
  height: 50px;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 16px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1B1B1B;

  &:focus, &:hover {
    border: 1px solid #55BED2 !important;
    box-shadow: 0 0 0 2px rgba(85, 190, 210, 0.20) !important;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #626262;
  }


  @media (max-width: 600px) {
    height: 44px;
    border: 1px solid #E9EEF6;
    border-radius: 8px;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #1B1B1B !important;
    padding: 13px 0px 13px 16px !important;
  }
`;
export const Textarea = styled(antd.Input.TextArea)`
  padding: 13px 0px 13px 20px;
  width: 100%;
  min-height: 115px !important;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 16px;

  &.ant-input-focused {
    border: 1px solid #55BED2 !important;
    box-shadow: 0 0 0 2px rgba(85, 190, 210, 0.20) !important;
  }

  &:focus, &:hover {
    border: 1px solid #55BED2 !important;
    box-shadow: 0 0 0 2px rgba(85, 190, 210, 0.20) !important;
  }
`;

export const Number: any = styled(InputNumber)`
  &.ant-input-number {
    padding: 13px 0px 13px 20px;
    width: 100%;
    height: 50px;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 16px;
    overflow: hidden;

    &.ant-input-number-focused {
      border: 1px solid #55BED2 !important;
      box-shadow: 0 0 0 2px rgba(85, 190, 210, 0.20) !important;
    }

    @media (max-width: 600px) {
      height: 44px;
      border: 1px solid #E9EEF6;
      border-radius: 8px;
      padding: 10px 0px 16px 10px;
    }
  }

  & .ant-input-number-input-wrap {
    height: 24px;
  }

  & .ant-input-number-input {
    height: 24px;
    padding-left: 0;
    padding-right: 30px;
    text-align: ${(props: { textAline: string }) => props.textAline === 'left' ? 'left' : 'right'};
  }
`;
