import React from "react";
import styled from "styled-components";

export const IndexWrapper = styled.div`
  text-align: center;
  display: flex;
  margin-bottom: -42px;

  .ant-divider-horizontal {
    min-width: calc(100% - 58px);
    margin: 0;
  }
`

export const IndexTitleWrapper = styled.div`
  text-align: center;
  width: 58px;
  height: 70.5px;
  background: #55BED2;
  clip-path: polygon(0% 0%, 100% 0, 100% 75%, 45% 100%, 0% 75%);

  @media (max-width: 600px) {
    width: 40px;
    height: 48px;
  }
`

export const IndexTitle = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 55px;
  color: #FFFFFF;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 40px;
  }
`

export const AddMoreButton = styled.div`
  cursor: pointer;
  width: 120px;
  height: 24px;
  display: flex;
  justify-content: space-around;
  /* label */
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #00C1D5;

  .__addMore_icon svg {
    width: 24px;
    height: 24px;
  }
`
