import React from "react";
import styled from "styled-components";
import {Checkbox} from 'antd';

export const Container = styled.div`
  display: flex;
`;
export const Text = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1B1B1B;
  margin-left: 16px;

  @media (max-width: 600px) {
    font-size: 14px;
    margin-left: 18px;
  }
`;

export const Checker = styled(Checkbox)`
  & .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00BCE8;
    border-color: #00BCE8;
  }

  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #00BCE8;
  }

  & .ant-checkbox-inner::after {
    left: 22.5% !important;
  }
`;