import Loading from "../components/Loading";
import React, {Suspense, useEffect} from "react";
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from "react-router-dom";
import Submitted from "../pages/epac/Submitted";
import {selectLoading} from "../store/appSlice";
import {useSelector} from "react-redux";
import NotFound from "../pages/404";

const App = React.lazy(() => import("App"));
const Login = React.lazy(() => import("../pages/auth/Login"));
const EpacScreen = React.lazy(() => import("../pages/epac"));
const SessionForm = React.lazy(() => import("../pages/epac/session"));
const HomePage = React.lazy(() => import("../pages/home/index"));

function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function AppRouter() {
    const loading = useSelector(selectLoading);
    return (
        <Suspense fallback={<Loading/>}>
            <Router>
                <ScrollToTop/>
                <Routes>
                    <Route path="/request/:id" element={<EpacScreen/>}/>
                    <Route path="/request/:id/submitted" element={<Submitted/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </Router>
            {
                loading ? <Loading/> : null
            }
        </Suspense>
    );
}

export default AppRouter;
