import React from "react";
import styled from "styled-components";
import Image404 from "../assets/images/404.svg";


export default function NotFound() {
    return (
        <Container style={{height: window.innerHeight}}>
            <ContainerView>
                <Img src={Image404}/>
                <Title>404</Title>
                <Text>Hey captain! Looks like you're heading to a wrong planet!</Text>
            </ContainerView>
        </Container>
    )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
`;
const ContainerView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px;
  flex: 1;
  height: 100%;
  width: 100%;
  max-width: 1000px;
  max-height: 700px;
`;
const Img = styled.img`
  padding: 0 100px;
`;
const Title = styled.h1`
  font-size: 90px;
  font-weight: bold;
  color: #000;
  margin-top: -50px;
  margin-bottom: 20px;
`;
const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #969696;
  margin-bottom: 0;
`;