import React, {useState} from "react";
import "./styles.css";
import {Divider} from "antd";
import {IndexTitle, IndexTitleWrapper, IndexWrapper} from "./styles";
import SpaceView from "../../Space";

interface SessionValue {
    index?: number;
}

interface SessionDivider {
    value?: SessionValue;
    onChange?: (value: SessionValue) => void;
}

const SessionDivider: React.FC<SessionDivider> = ({value = {}, onChange}) => {
    const [index, setIndex] = useState(value?.index || 1);
    function pad(d: number) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }
    return (
        <>
            <IndexWrapper>
                <IndexTitleWrapper>
                    <IndexTitle>{pad(index)}</IndexTitle>
                </IndexTitleWrapper>
                <Divider></Divider>
            </IndexWrapper>
        </>
    )
}

export default SessionDivider;
