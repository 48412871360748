import styled from "styled-components";
import TextArea from "antd/lib/input/TextArea";
import {Button, Col, Form, Tag} from "antd";
import PhoneInput from 'react-phone-input-2';

const {CheckableTag} = Tag;

export const EmailSubText = styled.span`
  margin-top: 2px;
  margin-left: 2px;
}`

export const Container = styled.div`
  max-width: 1408px;
  margin: 0 auto;
  background: #FFFFFF;
  backdrop-filter: blur(40px);
  border-radius: 16px;
  padding: 0 56px;

  @media (max-width: 600px) {
    padding: 0 16px;
    margin: 0 16px;
    position: relative;
  }
`

export const FormSessionContainer = styled.div`
  margin: 0 32px 0 127px;

  @media (max-width: 600px) {
    margin: 0;
    margin-top: 72px;

    & > .ant-row {
      display: block;

      .ant-col-11 {
        width: 100%;
        max-width: 100%;
        margin-bottom: 16px;
      }
    }

    & > .space-view {
      height: 0 !important;
    }
  }
`

export const StyledBackButton = styled(Button)`
  width: 272px;
  height: 56px;
  background: #EDFBFC;
  border: 1px solid #00C1D5;
  box-shadow: 0px 4px 30px rgba(0, 149, 153, 0.2);
  border-radius: 50px;

  & span {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #03B4CE;
  }
`

export const StepSessionC = styled.h3`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #1B1B1B;
  margin: 0;
`

export const CustomTextArea = styled(TextArea)`
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 16px;
  resize: none;
`

export const CustomTag = styled(CheckableTag)`
  padding: 20px 32px;
  height: 64px;
  border: 1px solid #BDC3CB;
  box-sizing: border-box;
  border-radius: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1B1B1B;
  display: flex;
  justify-content: space-between;

  &.ant-tag-checkable-checked {
    background: #EDFBFC;
    border: 1px solid #55BED2;
    box-sizing: border-box;
    border-radius: 24px;
  }
`

export const CustomPhoneInput = styled(PhoneInput)`
  .form-control {
    padding: 13px 0px 13px 20px !important;
    width: 100% !important;
    height: 50px !important;
    border: 1px solid #DDDDDD !important;
    box-sizing: border-box !important;
    border-radius: 16px !important;

    &:focus, &:hover {
      border: 1px solid #55BED2 !important;
      box-shadow: 0 0 0 2px rgba(85, 190, 210, 0.20) !important;
    }
  }
`

export const StyledForm = styled(Form)`
  background: linear-gradient(244.89deg, #D7F8F8 7.39%, #F9DAC6 100%);
  @media (max-width: 600px) {
    overflow: auto;
    height: 100%;
  }
`

export const DocumentForm = styled.div`
  @media (max-width: 1200px) {
    flex-direction: column;
  }

  display: flex;
`

export const Icon = styled.button.attrs({ type: 'button' })`
  min-width: 6rem;
  display: block;
  padding: 1rem 1rem 1rem;

  background: none;
  border: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  cursor: pointer;
  margin: 0;

  :empty {
    cursor: default;
  }
`

export const DocumentFormStep = styled.div`
  @media (max-width: 1200px) {
    display: flex;

    /* rows */
    :last-child > *,
    :first-child > * {
      align-self: center;
    }

    :first-child ${Icon} img {
      margin-bottom: -50%;
    }

    :last-child {
      padding-top: 0 !important;
    }

    /* cols */
    > :first-child {
      min-width: 5rem;
    }

    > :last-child {
      flex: 1;
    }

    & + & {
      border-top: 2px solid rgba(0, 0, 0, 0.06);
    }

    ${Icon} img {
      height: 64px !important;
    }
      
    [aria-role=button] > :last-child {
      height: 0;
    }

    [role=alert] {
      position: absolute;
      margin-left: 100%;
      width: 50vw;
    }

    label {
      font-size: 20px;
      font-weight: normal;
    }

    padding: 1rem 1rem 0 0 !important;
    border-left: none !important;
    width: 100%;
  }

  & + & {
    border-left: 2px solid rgba(0, 0, 0, 0.06);
  }

  :first-child {
    padding-right: 3rem;
    flex-grow: 3;
  }

  :first-child + * {
    flex-grow: 3;
    padding: 0 3rem;
  }

  :last-child {
    padding-left: 3rem;
    flex-grow: 4;
  }

  ${Icon} {
    margin: auto;
  }

  ${Icon} img {
    height: 110px;
  } 
`