import React, { ReactElement, useEffect, useState } from "react";
import { Col, Form, message, Row } from "antd";
import SpaceView from "../../../components/Space";
import {
    Container,
    FormSessionContainer
} from "../styles";
import { Title } from "../../../components/Form/Session/styles";
import SessionDivider from "../../../components/Form/SessionDivider";
import {
    Label,
    LabelRegular,
    LabelRegular10,
    LabelRegular11,
    LabelRegular3,
    LabelRegular5,
    LabelRegular6,
    LabelRegular7,
    LabelRegular8,
    LabelRegular9,
} from "../../../components/Form/TextInput/styles";
import { Center } from "../../../components/Layout";
import Signature from "../../../assets/images/signature.png";
import SubmittedIcon from "../../../assets/images/submitted.png";
import ShareIcon from "../../../assets/images/shareIcon.png";
import Header from "../../../components/Header";
import { StyledSharedButton, StyledContainer, StyledStatus } from "./styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EpacFormType } from "../../../types/epac.type";
import moment from "moment";
import { formatPhone, getUserInfo } from "../../../services";
import Checkbox from "../../../components/Form/Checkbox";
import { PhoneNumberInput } from "../../../components/Form/PhoneInput";
import { setLoading } from "../../../store/appSlice";
import { useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Loading from "components/Loading";
// @ts-ignore
import { saveAs } from "file-saver";
import { FOREIGNER_FORM_FILE_NAME } from "../../../constants";

const DATE_FORMAT = "DD-MM-YYYY";

export default function Submitted(props: any) {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const printRef = React.useRef<HTMLDivElement>(null);

    const [formValue, setFormValue] = useState<any>(
        location.state as EpacFormType
    );
    const [isLoading, setIsLoading] = useState<Boolean>(false);

    const { id }: any = useParams();

    useEffect(() => {
        initData().then();
    }, []);

    const handleDownloadPdf = async () => {
        setIsLoading(true);
        const element = printRef?.current as HTMLElement | any;
        element.style.display = "block";
        const canvas = await html2canvas(element as HTMLElement, {
            scale: 2,
            proxy: formValue.info_warranty_sign + "?not-from-cache-please"
        });
        const data = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        pdf.addImage(data, "PNG", 0, 0, width, height);
        var blob = new Blob([pdf.output('blob')], {
            type: 'application/pdf'
        });
        saveAs(blob, "Pre-Authorisation Request Form.pdf");
        element.style.display = "none";
        setIsLoading(false);
    };

    const initData = async () => {
        dispatch(setLoading(true));
        const { data, status } = await getUserInfo(id);
        if (status === 200 && data?.data) {
            setFormValue({
                ...data?.data,
                case_id: id,
            });
        } else {
            message.error("Case is not valid, please check again!", 9999);
            navigate(`/404`);
        }
        dispatch(setLoading(false));
    };

    const copyPreviewLink = () => {
        handleDownloadPdf();
        // navigator.clipboard
        //     .writeText(`${window.location.host}/request/${id}/submitted`)
        //     .then((r) => {
        message.success("Export PDF!");
        // });
    };

    const isAge21 = formValue?.dob
        ? moment(Date.now())
            .startOf("months")
            .diff(moment(`01/${formValue?.dob.slice(-7)}`, DATE_FORMAT).startOf("months"), "years") >=
        21
        : true;

    if (isLoading || !formValue) return <Loading />;

    const sections = Array<ReactElement>()
    const addSection = (section: ReactElement) => {
        const index = sections.length + 1
  
        sections.push(<>
            <SessionDivider value={{ index }}/>
            {section}
        </>)
    }
    
    addSection(<>
        <FormSessionContainer>
            <Row>
                <Col span={7}>
                    <LabelRegular7>Name (Same as NRIC/ Passport)</LabelRegular7>
                    <SpaceView size={16} />
                    <LabelRegular3>{formValue?.full_name}</LabelRegular3>
                </Col>
                <Col span={1} />
                <Col span={7}>
                    <LabelRegular7>NRIC No. </LabelRegular7>
                    <SpaceView size={16} />
                    <LabelRegular3>{formValue?.nric_no}</LabelRegular3>
                </Col>
                <Col span={1} />
                <Col span={7}>
                    <LabelRegular7>Policy No.</LabelRegular7>
                    <SpaceView size={16} />
                    <LabelRegular3>
                        {formValue?.gsh_info?.policy_no || ""}
                    </LabelRegular3>
                </Col>
            </Row>
            <SpaceView size={20} />
            <Row>
                <Col span={7}>
                    <LabelRegular7>Nationality</LabelRegular7>
                    <SpaceView size={16} />
                    <LabelRegular3>{formValue?.nationality}</LabelRegular3>
                </Col>
                <Col span={1} />
                <Col span={7}>
                    <LabelRegular7>Date of Birth</LabelRegular7>
                    <SpaceView size={16} />
                    {/* <LabelRegular3>
                        {moment(formValue?.dob, "DD/MM/YYYY").format("DD-MMM-YYYY")}
                    </LabelRegular3> */}
                    <LabelRegular3>{formValue?.dob}</LabelRegular3>
                </Col>
                <Col span={1} />
                <Col span={7}>
                    <LabelRegular7>Mobile No.</LabelRegular7>
                    <SpaceView size={16} />
                    <LabelRegular3>
                        {formatPhone(formValue?.phone_number?.includes("+") ? formValue?.phone_number : "+"+formValue?.phone_number)}
                    </LabelRegular3>
                </Col>
            </Row>
            <SpaceView size={20} />
            <Row>
                <Col span={7}>
                    <LabelRegular7>Registered Address</LabelRegular7>
                    <SpaceView size={16} />
                    <LabelRegular3>
                        {formValue?.registered_address || ""}
                    </LabelRegular3>
                </Col>
                <Col span={1} />
                <Col span={7}>
                    <LabelRegular7>Home Telephone No.</LabelRegular7>
                    <SpaceView size={16} />
                    <LabelRegular3>
                        {formatPhone(formValue?.home_tel_number)}
                    </LabelRegular3>
                </Col>
                <Col span={1} />
                <Col span={7}>
                    <LabelRegular7>Email Address</LabelRegular7>
                    <SpaceView size={16} />
                    <LabelRegular3>{formValue?.email}</LabelRegular3>
                </Col>
            </Row>
        </FormSessionContainer>
        <SpaceView size={32} />
    </>);

    addSection(<>
        <FormSessionContainer>
            <Row>
                <Col span={24}>
                    <LabelRegular
                        className={"title"}
                        style={{
                            fontSize: 20,
                            fontWeight: "600",
                            color: "#1B1B1B",
                        }}
                    >
                        Does your employer provide you with inpatient coverage?{" "}
                        <span
                            style={{
                                display: "inherit",
                                fontSize: 22,
                                fontWeight: "400",
                                paddingLeft: 6,
                            }}
                        >
                            {formValue.inpatient_coverage ? "Yes" : "No"}
                        </span>
                    </LabelRegular>
                </Col>
                {formValue.inpatient_coverage ? (
                    <div className={"view-submited"} style={{ width: "100%" }}>
                        <SpaceView size={16} />
                        <Row>
                            <Col span={7}>
                                <LabelRegular3>
                                    Insurer:{" "}
                                    <LabelRegular11>
                                        {formValue.insurer}
                                    </LabelRegular11>
                                </LabelRegular3>
                            </Col>
                            <Col span={1} />
                            <Col span={7}>
                                <LabelRegular3>
                                    Type of policy:{" "}
                                    <LabelRegular11>
                                        {formValue?.type_of_policy}
                                    </LabelRegular11>
                                </LabelRegular3>
                            </Col>
                            <Col span={1} />
                            <Col span={7}>
                                <LabelRegular3>
                                    Policy No.:{" "}
                                    <LabelRegular11>{formValue?.policy_no}</LabelRegular11>
                                </LabelRegular3>
                            </Col>
                        </Row>
                        <SpaceView size={12} />
                    </div>
                ) : null}
            </Row>
        </FormSessionContainer>
        <div className={"space-mobile-2"} />
        <SpaceView size={32} />
    </>)

    const { authorisation_letter } = formValue
    if (authorisation_letter) {
        const extOffset = authorisation_letter.lastIndexOf('.');
        const name = FOREIGNER_FORM_FILE_NAME + authorisation_letter.substring(extOffset)

        addSection(
            <>
                <FormSessionContainer>
                    <Row>
                        <Col span={24}>
                            <Label className={"title"}>
                                Letter of Authorisation
                            </Label>
                            <SpaceView size={8} />
                            <LabelRegular6>
                                <a href={authorisation_letter} rel="noreferrer" download={name}>{name}</a>
                            </LabelRegular6>
                        </Col>
                    </Row>
                    
                </FormSessionContainer>
                <SpaceView size={32} />
            </>
        )
    }

    addSection(
        <FormSessionContainer>
            <Row>
                <Col span={24}>
                    <Label theme={{ marginBottom: "0" }} className={"title"}>
                        Declaration
                    </Label>
                    <SpaceView size={8} />
                    <LabelRegular6 className={"epac-form"}>
                        By signing below, I warrant that the information given in this form is true, correct and complete. 
                        I agree that this form and these declarations shall be the basis of the Company to evaluate my 
                        Pre-authorisation request and I accept the term and conditions of the Health Connect service. 
                        In the event the Company becomes aware there is a material fact which is not disclosed in this form, 
                        the Certificate of Pre-authorisation issued may not be valid.<br /><br />

                        I hereby give my consent and authorise the doctor(s), clinic(s), hospital(s) or any person 
                        who has attended to or examined me or is authorised to maintain my medical record to furnish 
                        and release my medical report to the ‘Great Eastern Life Assurance’ (Insurer), 
                        its appointed administrator, and its staff and associates with respect to any of my illnesses or injuries, 
                        medical history, consultations, prescriptions or treatment.<br /><br />

                        By providing the information set out above, I agree and consent to Great Eastern, its related 
                        corporations (collectively, the &ldquo;Companies&rdquo;), as well as their respective representatives 
                        and agents (&ldquo;Representatives&rdquo;) collecting, using, disclosing and sharing amongst 
                        themselves my/ my child’s personal data, and disclosing such personal data to 
                        the Companies’ authorised service providers and relevant third parties for purposes reasonably 
                        required by the Companies to evaluate, admit, process and/or settle my/ my child’s claims. 
                        These purposes are set out in Great Eastern's Privacy Statement, which is accessible at {' '}
                        <a href="https://www.greateasternlife.com/sg/en/privacy-and-security-policy.html" 
                            target={'_blank'} rel="noreferrer">Privacy and Security Policy | Great Eastern Singapore</a>{' '}
                        and which I confirm I have read and understood.<br /><br /> 

                        I also agree to the Insurer or Company seeking information from any source and I authorise the giving of 
                        such information. In addition, I hereby agree that this consent shall remain valid not withstanding my death.<br /><br /> 

                        To enable Great Eastern to seek reimbursement from the Other Insurer/Company of all and any claims Great Eastern has paid out 
                        in respect of the Hospitalisation (&ldquo;Reimbursement&rdquo;), I authorise and agree to the following: 
                        <ol>
                            <li style={{marginBottom: "8px", paddingLeft: "1rem"}}>
                            Great Eastern can collect, use of disclose, as they reasonably consider appropriate, any information or documents they have 
                                to allow them to seek Reimbursement, such as:
                                <ol type="a">
                                    <li style={{paddingLeft: "1rem"}}>Great Eastern’s claim settlement letters issued to me; and</li>
                                    <li style={{paddingLeft: "1rem"}}>Medical invoices issued by the Life Assured’s medical provider.</li>
                                </ol>
                            </li>
                            <li style={{paddingLeft: "1rem"}}>
                            The Other Insurer/Company (including as Human Resource personnel, where appropriate) can liaise with 
                            Great Eastern directly regarding the procedures and/or documentation required for the Reimbursement. 
                            I understand that I will still be required to sign-off on the relevant claim forms
                            </li>
                        </ol> 
                        I agree and consent to Great Eastern, its related corporations (collectively, the &ldquo;Companies&rdquo;), 
                        as well as the respective representatives, agents, the Companies’ authorised service providers and 
                        relevant third parties, and the Other Insurer/Company, collecting, using and/or disclosing my/our 
                        personal data for purposes reasonably required by Great Eastern to seek Reimbursement.
                    </LabelRegular6>
                    <SpaceView size={40} />
                    <Checkbox
                        checked={formValue.was_referred}
                        label={
                            "I was referred to this specialist by the Health Connect team"
                        }
                    />
                    <SpaceView size={40} />
                    <Center
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                        }}
                    >
                        <LabelRegular8>
                            {moment(Date.now()).diff(
                                moment(`01/${formValue.dob.slice(-7)}`, DATE_FORMAT),
                                "years"
                            ) >= 21
                                ? "Signature of Life Assured"
                                : "Signature of Parent/Guardian (if Life Assured is under 21 years of age)"}
                        </LabelRegular8>
                        <SpaceView size={8}></SpaceView>
                        <LabelRegular9>
                            {moment(Date.now()).format("dddd, MMM DD, YYYY")}
                        </LabelRegular9>
                        <SpaceView size={8}></SpaceView>
                        <div>
                            <img
                                src={formValue.info_warranty_sign}
                                alt=""
                                width={262}
                                height={142}
                            />
                        </div>
                    </Center>
                </Col>
            </Row>
        </FormSessionContainer>
    )

    return formValue ? (
        <>
            <StyledContainer>
                <Header hideMenu={true} hideAccount={true} />
                <Container style={{
                    position: "relative",
                    zIndex: 1,
                }}>
                    <StyledSharedButton
                        onClick={copyPreviewLink}
                        icon={
                            <img
                                src={ShareIcon}
                                alt=""
                                width={24}
                                height={24}
                                style={{ marginRight: "12px" }}
                            />
                        }
                    >
                        Share
                    </StyledSharedButton>
                </Container>
                <Container ref={printRef} style={{ zIndex: 0 }}>
                    <SpaceView className={"space-mobile"} size={64} />
                    <Center
                        className={"top-mobile"}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                        }}
                    >
                        <div>
                            <img
                                className={"img-top-mobile"}
                                src={SubmittedIcon}
                                alt=""
                                width={88.7}
                                height={88.7}
                            />
                        </div>
                        <SpaceView size={12} />
                        <LabelRegular10>Your form has been submitted!</LabelRegular10>
                        <SpaceView size={12} />
                        <StyledStatus>In Admin Review</StyledStatus>
                    </Center>
                    <SpaceView size={32} />
                    <Title style={{ color: "#1B1B1B" }}>
                        Pre-Authorisation Request Form
                    </Title>
                    <SpaceView size={32} />
                    {sections}
                    {!isAge21 ? (
                        <>
                            <SpaceView size={32} />
                            <SessionDivider value={{ index: sections.length }}></SessionDivider>
                            <FormSessionContainer>
                                <Row>
                                    <Col span={24}>
                                        <Label theme={{ marginBottom: "0" }} className={"title"}>
                                            Life Assured / Guardian Consent
                                        </Label>
                                        <SpaceView size={8} />
                                        <LabelRegular6 className={"epac-form"}>
                                            I, <b>{formValue.full_name}</b> (Life Assured / Guardian
                                            name) <b>{formValue.nric_no}</b> (NRIC/PP No.), hereby
                                            give my consent and authorise the doctor(s), clinic(s),
                                            hospital(s) or any person who has attended to or examined
                                            me/my child or is authorised to maintain my/my child’s
                                            medical record to furnish and release my/ my child’s
                                            medical report to the ‘Great Eastern Life Assurance’
                                            (Insurer), its appointed administrator, and its staff and
                                            associates with respect to any of my illnesses or
                                            injuries, medical history, consultations, prescriptions or
                                            treatment.
                                        </LabelRegular6>
                                        <SpaceView size={16} />
                                        <LabelRegular6>
                                            By providing the information set out above, I agree and
                                            consent to Great Eastern, its related corporations
                                            (collectively, the "Companies"), as well as their
                                            respective representatives and agents ("Representatives")
                                            collecting, using, disclosing and sharing amongst
                                            themselves my/ my child’s personal data, and disclosing
                                            such personal data to the Companies' authorised service
                                            providers and relevant third parties for purposes
                                            reasonably required by the Companies to evaluate, admit,
                                            process and/or settle my/ my child’s claims. These
                                            purposes are set out in Great Eastern's Privacy Statement,
                                            which is accessible at{" "}
                                            <a href="https://www.greateasternlife.com/sg/en/privacy-and-security-policy.html">
                                                https://www.greateasternlife.com/sg/en/privacy-and-security-policy.html
                                            </a>{" "}
                                            and which I confirm I have read and understood.
                                        </LabelRegular6>
                                        <SpaceView size={16} />
                                        <LabelRegular6>
                                            I also agree to the Insurer or Company seeking information
                                            from any source and I authorise the giving of such
                                            information. A photocopy of this authorisation is as valid
                                            as the original. In addition, I hereby agree that this
                                            consent shall remain valid not withstanding my death.
                                        </LabelRegular6>
                                        <SpaceView size={40} />
                                        <Center
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-around",
                                            }}
                                        >
                                            <LabelRegular8>
                                                {moment(Date.now()).diff(
                                                    moment(`01/${formValue.dob.slice(-7)}`, DATE_FORMAT),
                                                    "years"
                                                ) >= 21
                                                    ? "Signature of Life Assured"
                                                    : "Signature of Parent/Guardian (if Life Assured is under 21 years of age)"}
                                            </LabelRegular8>
                                            <SpaceView size={8}></SpaceView>
                                            <LabelRegular9>
                                                {moment(Date.now()).format("dddd, MMM DD, YYYY")}
                                            </LabelRegular9>
                                            <SpaceView size={8}></SpaceView>
                                            <div>
                                                <img
                                                    src={formValue.life_assured_sign}
                                                    alt=""
                                                    width={262}
                                                    height={142}
                                                />
                                            </div>
                                        </Center>
                                    </Col>
                                </Row>
                            </FormSessionContainer>
                        </>
                    ) : null}
                    <SpaceView size={60} />
                </Container>
            </StyledContainer>
        </>
    ) : null;
}
