import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  height: 230px;
  background: #55BED2;
  border-radius: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`

export const Items = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex: 1;
`

export const Item = styled.div`
  text-align: center;
  width: 33%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: white;
    left: -50%;
    top: 20px;
    z-index: 1;
  }

  ${(props: { active: boolean }) => props.active ? `
    &:before {
      background-color: #EF827B;
    }
  ` : ``
  }
  &:first-child:before {
    display: none;
  }
`

export const ItemNumber = styled.span`
  width: 40px;
  height: 40px;
  background: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #989898;
  border-radius: 20px;
  margin-bottom: 12px;
  position: relative;
  z-index: 2;

  ${(props: { active: boolean }) => props.active ? `
   background: #EF827B;
   color: #FFFFFF;
  ` : ``
  }
`
export const ItemTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin: 0;
  margin-bottom: 4px;
`
export const ItemDesc = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
  height: 40px;
`
export const Title = styled.h3`
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #55BED2;
  margin: 0;
  text-align: center;

  span {
    color: #000;
  }

  @media (max-width: 600px) {
    padding: 0 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    span {
      color: #1B1B1B;
    }
  }
`
