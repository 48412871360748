import React from "react";
import styled from "styled-components";
import TextArea from "antd/lib/input/TextArea";
import {Button, Tag} from "antd";
import PhoneInput from 'react-phone-input-2';

const {CheckableTag} = Tag;

export const Container = styled.div`
  max-width: 1408px;
  margin: 0 auto;
`

export const FormSessionContainer = styled.div`
  margin: 0 32px 0 127px;
`

export const StyledBackButton = styled(Button)`
  padding: 0px 12px;
  width: 272px;
  height: 56px;
  background: #EDFBFC;
  border: 1px solid #00C1D5;
  box-shadow: 0px 4px 30px rgba(0, 149, 153, 0.2);
  margin: 0px 20px;
  border-radius: 50px;
`

export const StepSessionC = styled.h3`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #1B1B1B;
  margin: 0;
`

export const CustomTextArea = styled(TextArea)`
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 16px;
  resize: none;
`

export const CustomTag = styled(CheckableTag)`
  padding: 20px 32px;
  height: 64px;
  border: 1px solid #BDC3CB;
  box-sizing: border-box;
  border-radius: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1B1B1B;
  display: flex;
  justify-content: space-between;

  &.ant-tag-checkable-checked {
    background: #EDFBFC;
    border: 1px solid #55BED2;
    box-sizing: border-box;
    border-radius: 24px;
  }
`

export const CustomPhoneInput = styled(PhoneInput)`
  .form-control {
    padding: 13px 0px 13px 20px !important;
    width: 100% !important;
    height: 50px !important;
    border: 1px solid #DDDDDD !important;
    box-sizing: border-box !important;
    border-radius: 16px !important;

    &:focus, &:hover {
      border: 1px solid #55BED2 !important;
      box-shadow: 0 0 0 2px rgba(85, 190, 210, 0.20) !important;
    }
  }
`

export const StyledStatus = styled.div`
  padding: 6px 12px;
  width: 155px;
  height: 32px;
  background: #FFF0E6;
  border-radius: 20px;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FE6B00;
`

export const StyledContainer = styled.div`
  background: linear-gradient(244.89deg, #D7F8F8 7.39%, #F9DAC6 100%);
 
  
  @media (max-width: 600px) {
    overflow: auto;
    height: 100%;
    & .space-mobile {
      height: 112px;
    }

    & .top-mobile img {
      height: 40px;
      width: 40px;
    }

    & .top-mobile div:nth-child(3) {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    & .top-mobile div:nth-child(5) {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
    }

    & .ant-col-7 {
      width: 100%;
      max-width: 100%;
      margin-bottom: 24px;

      label {
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #1B1B1B !important;
      }

      .space-view {
        height: 8px !important;
      }

      div {
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        display: flex !important;
        color: #1B1B1B !important;
      }
    }
  }
`

export const StyledSharedButton = styled(Button)`
  padding: 0px 12px 0px 0px;
  width: 163px;
  height: 56px;
  background: #EDFBFC;
  border: 1px solid #00C1D5;
  box-shadow: 0px 4px 30px rgba(0, 149, 153, 0.2);
  border-radius: 50px;

  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #03B4CE;
  position: absolute;
  top: 39px;
  right: 56px;

  @media (max-width: 600px) {
    top: 40px;
    right: 16px;
    height: 48px;
    font-size: 14px;
    width: 142px;
    box-shadow: none;

    & img {
      width: 18px;
      height: 18px;
    }
  }
`
