import React from "react";
import styled from "styled-components";

const SpaceView = styled((props: any) => {
    return <div {...props} className={`space-view ${props.className}`} />
})`
  width: 100%;
  height: ${(props: { size: number }) => props.size}px;
`;

export default SpaceView;