import React from "react";
import styled from "styled-components";

export const Row = styled.div`
  display: flex;
`;
export const Cell = styled.div`
  min-height: 1px;
  width: ${(props: { size: string }) => props.size};
`;
export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;