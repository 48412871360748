import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 36px;
  width: 100%;
  height: 96px;
  @media (max-width: 600px) {
    padding: 24px 16px;
  }
`

export const Menus = styled.div`
`

export const Menu = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #494949;
  margin: 0px 32px;
  position: relative;
  display: inline-block;
  ${(props: { active?: boolean }) => props.active ? `
    font-weight: 500;
    color: #00C1D5 !important;
    &:after{
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      background: #00C1D5;
      border-radius: 8px;
      height: 4px;
      width: 100%;
    }
  ` : ''}
`

export const Logo = styled.div`
  display: flex;
  height: 42px;

  > * + * {
    margin-left: 1rem;
  }
`

export const Account = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const AccountText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101820;
  margin-right: 9px;
`

export const AccountIcon = styled.img`
`
