import React from "react";
import {Account, AccountIcon, AccountText, Container, Logo, Menu, Menus} from "./styles";
import {useSelector} from "react-redux";
import {selectUserSelector} from "../../pages/auth/authSlice";
import AvatarIcon from "../../assets/images/avt.png"
import LogoIcon from "../../assets/images/logo.png"
import GEIcon from "../../assets/images/ge.png"
import {Link} from "react-router-dom";

export default function Header(props: any) {
  const user = useSelector(selectUserSelector);

  return (
    <Container>
        <Logo>
            <img src={LogoIcon} />
            <img src={GEIcon} />
        </Logo>

        {
            !props?.hideMenu && <Menus>
                <Menu><Link to={'/home'}>Home</Link></Menu>
                <Menu>Search for specialist</Menu>
                <Menu active><Link to={'/epac'}>ePAC</Link></Menu>
            </Menus>
        }

        {
            !props?.hideAccount && <Account>
                <AccountText>Hi! James Truong</AccountText>
                <AccountIcon src={AvatarIcon}/>
            </Account>
        }
    </Container>
  )
}
